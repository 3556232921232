import React, { useState, useEffect } from "react";
import loader from "./../images/loader.gif";
import "./../index.css";
import Block from "./Block";
import { firestore } from "./../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

const Dashboard = (props) => {
  const [online, setOnline] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const [words, setWords] = useState(null);
  const [wordsPool, setWordsPool] = useState({
    unused: [],
    used: [],
    lastVisible: null,
  });

  const [selectedDate, setSelectedDate] = useState(
    new Date().toLocaleDateString("he-IL").replaceAll(".", "-")
  );

  useEffect(() => {
    const fetchData = async () => {
      // Setup real-time listeners for online users
      const onlineUsersQuery = query(
        collection(firestore, "users"),
        where("isOnline", "==", true)
      );
      const unsubscribeOnline = onSnapshot(onlineUsersQuery, (snapshot) => {
        const onlineTmp = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          onlineTmp.push({
            username: data.username,
            id: doc.id,
            lastOnline: data.lastOnline ? data.lastOnline : null,
            type:
              data.loginType == 0
                ? "google"
                : data.loginType == 1
                ? "apple"
                : "anonymous",
          });
        });
        setOnline(onlineTmp);
      });

      // Fetch statistics for selected date
      // const fetchStats = async (date) => {
      //   const docRef = doc(firestore, "statistics", date);
      //   const docSnap = await getDoc(docRef);
      //   if (docSnap.exists()) {
      //     setStats(docSnap.data());
      //   } else {
      //     setStats(null);
      //   }
      // };

      const unsubscribeStats = onSnapshot(
        doc(firestore, "statistics", selectedDate),
        (doc) => {
          if (doc.exists()) {
            setStats(doc.data());
          } else {
            setStats(null);
          }
        }
      );
      // Fetch today's secret words
      const unsubscribeTodaysWords = onSnapshot(
        collection(firestore, "todays_words"),
        async (snapshot) => {
          const todaysWords = snapshot.docs.map((doc) => ({
            id: doc.id,
            word: doc.data().word,
          }));

          const wordsData = {
            solo: todaysWords
              .filter((word) => word.id === "solo")
              .map((word) => word.word),
            ad: todaysWords
              .filter((word) => word.id === "ad")
              .map((word) => word.word),
          };

          setWords(wordsData);
        }
      );

      const unsubscribeWordsPool = onSnapshot(
        collection(firestore, "unused_words"),
        async (snapshot) => {
          const unusedWords = snapshot.docs.map((doc) => ({
            id: doc.id,
            word: doc.data().word,
            status: "Unused",
          }));
          const usedWordsSnapshot = await getDocs(
            collection(firestore, "used_words")
          );
          const usedWords = usedWordsSnapshot.docs.map((doc) => ({
            id: doc.id,
            word: doc.data().word,
            status: "Used",
          }));
          const wordsPool = [...unusedWords, ...usedWords];
          setWordsPool({
            words: wordsPool,
            lastVisible: snapshot.docs[snapshot.docs.length - 1],
          });
        }
      );

      setIsLoading(false);

      // Cleanup function to unsubscribe from real-time updates
      return () => {
        unsubscribeOnline();
        unsubscribeWordsPool();
        unsubscribeTodaysWords();
        unsubscribeStats();
      };
    };

    fetchData();
  }, [selectedDate]);

  return isLoading ? (
    <div className="dashboard">
      <img src={loader} alt="Loading..." />
    </div>
  ) : (
    <div className="dashboard">
      <Block type="online" data={online} />
      {/* <Block type="words" data={words} /> */}
      <Block
        type="wordPool"
        data={wordsPool}
        todaysWords={words}
        setData={setWordsPool}
      />
      <Block
        type="stats"
        data={stats}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  );
};

export default Dashboard;
