import React, { useState, useEffect } from "react";
import "./App.css";
import Dashboard from "./components/Dashboard";
import { auth, googleProvider } from "./firebase";
import {
  getRedirectResult,
  signInWithRedirect,
  onAuthStateChanged,
} from "firebase/auth";
import loader from "./images/loader.gif";

const App = () => {
  const [firebaseLoaded, setFirebaseLoaded] = useState(false);

  useEffect(() => {
    const loadFirebase = async () => {
      const isAuthorizedUser = (user) =>
        user &&
        ["liavkuperman@gmail.com", "ronnyasraf6@gmail.com"].includes(
          user.email
        );

      const handleAuthStateChanged = (user) => {
        if (isAuthorizedUser(user)) {
          setFirebaseLoaded(true);
        } else {
          signInWithRedirect(auth, googleProvider);
        }
      };

      onAuthStateChanged(auth, handleAuthStateChanged);

      try {
        const result = await getRedirectResult(auth);
        if (result && isAuthorizedUser(result.user)) {
          setFirebaseLoaded(true);
        } else {
        }
      } catch (error) {
        console.error("Error during authentication:", error);
      }
    };

    loadFirebase();
  }, []);

  return (
    <div className="App">
      {firebaseLoaded ? (
        <Dashboard />
      ) : (
        <div
          className="dashboard"
          style={{
            height: "100vh",
            width: "100%",
            margin: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={loader} alt="Loading..." />
        </div>
      )}
    </div>
  );
};

export default App;
