import React, { useState, useEffect } from "react";
import "./../index.css";
import { firestore } from "./../firebase";
import { saveAs } from "file-saver";
import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  writeBatch,
} from "firebase/firestore";

import {
  PieChart,
  Pie,
  Cell,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { IoMdClose, IoIosRefresh } from "react-icons/io";
import {
  FaGoogle,
  FaApple,
  FaUser,
  FaArrowLeft,
  FaArrowRight,
} from "react-icons/fa";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const Block = (props) => {
  const type = props.type;
  const data = props.data; // Ensure data structure

  const [showSoloWord, setShowSoloWord] = useState(false);
  const [showAdWord, setShowAdWord] = useState(false);
  const [newWord, setNewWord] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "word",
    direction: "asc",
  });
  const [wordsPagination, setWordsPagination] = useState(20);

  const handleDateChange = (offset) => {
    const [day, month, year] = props.selectedDate.split("-");
    const date = new Date(`${year}-${month}-${day}`);
    date.setDate(date.getDate() + offset);
    const newDate = date
      .toLocaleDateString("en-GB")
      .split("/")
      .map((part) => (part.length === 1 ? "0" + part : part))
      .reverse()
      .join("-");

    let newFormat = newDate.split("-").reverse().join("-");
    if (newFormat.charAt(0) === "0") {
      newFormat = newFormat.slice(1);
    }
    newFormat = newFormat.replace("-0", "-");
    props.setSelectedDate(newFormat);
  };

  const setAllUsersOffline = async () => {
    try {
      const batch = writeBatch(firestore);

      data.forEach((user) => {
        const userRef = doc(collection(firestore, "users"), user.id);
        batch.update(userRef, { isOnline: false });
      });

      await batch.commit();
      data = [];
    } catch (error) {}
  };

  const setUserOffline = async (id) => {
    const user = data.find((user) => user.id === id);
    if (
      user &&
      window.confirm(`Are you sure you want to set ${user.username} offline?`)
    ) {
      try {
        const userRef = doc(collection(firestore, "users"), id);
        await updateDoc(userRef, { isOnline: false });
      } catch (error) {
        console.error(`Error setting user ${user.username} offline:`, error);
      }
    }
  };

  const renderOnlineBlock = () => {
    const sortedUsers = [...data].sort((a, b) => {
      if (a.lastOnline && b.lastOnline) {
        return a.lastOnline.seconds - b.lastOnline.seconds;
      }
      return 0;
    });
    return (
      <div>
        <button onClick={setAllUsersOffline} className="set-offline-button">
          Set All Users Offline
        </button>
        <table className="blockTable" id="onlineTable">
          <thead>
            <tr>
              <th class="type"></th>
              <th>שם</th>
              <th>מחובר מ</th>
              <th>הפוך ללא מחובר</th>
            </tr>
          </thead>
          <tbody>
            {sortedUsers.map((user, index) => (
              <tr key={user.id}>
                <td>
                  {user.type === "anonymous" ? (
                    <FaUser />
                  ) : user.type === "google" ? (
                    <FaGoogle style={{ color: "#EA4335" }} />
                  ) : (
                    <FaApple />
                  )}
                </td>
                <td>
                  <a
                    href={`https://console.firebase.google.com/u/0/project/wordle-c7049/firestore/databases/-default-/data/~2Fusers~2F${user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {user.username}
                  </a>
                </td>
                <td>
                  {user.lastOnline
                    ? `${new Date(
                        user.lastOnline.seconds * 1000
                      ).toLocaleString("he-IL")} (${getTimeLapsed(
                        user.lastOnline.seconds
                      )} ago)`
                    : "N/A"}
                </td>
                <td>
                  {/* <ion-icon
                    name="close"
                    style={{
                      color: "red",
                      fontSize: "20pt",
                      cursor: "pointer",
                    }}
                    onClick={() => setUserOffline(user.id)}
                  /> */}
                  <IoMdClose
                    style={{
                      color: "red",
                      fontSize: "20pt",
                      cursor: "pointer",
                    }}
                    onClick={() => setUserOffline(user.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderStatsBlock = () => {
    if (!data) {
      return <div>No data available for the selected date.</div>;
    }

    const soloData = [
      { name: "Regular Wins", value: data.solo.wins.regular },
      { name: "Two more tries Wins", value: data.solo.wins.extra },
      { name: "Regular Loses", value: data.solo.loses.regular },
      { name: "Two more tries Loses", value: data.solo.loses.extra },
    ];

    const adData = [
      { name: "Regular Wins", value: data.ad.wins.regular },
      { name: "Two more tries Wins", value: data.ad.wins.extra },
      { name: "Regular Loses", value: data.ad.loses.regular },
      { name: "Two more tries Loses", value: data.ad.loses.extra },
    ];

    const totalGames =
      data.solo.wins.regular +
      data.solo.wins.extra +
      data.solo.loses.regular +
      data.solo.loses.extra +
      data.ad.wins.regular +
      data.ad.wins.extra +
      data.ad.loses.regular +
      data.ad.loses.extra;

    return (
      <div className="stats-block">
        <table className="blockTable">
          <thead>
            <tr>
              <th>Game Type</th>
              <th>Regular Wins</th>
              <th>Two more tries Wins</th>
              <th>Regular Loses</th>
              <th>Two more tries Loses</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Solo</td>
              <td>{data.solo.wins.regular}</td>
              <td>{data.solo.wins.extra}</td>
              <td>{data.solo.loses.regular}</td>
              <td>{data.solo.loses.extra}</td>
            </tr>
            <tr>
              <td>Ad</td>
              <td>{data.ad.wins.regular}</td>
              <td>{data.ad.wins.extra}</td>
              <td>{data.ad.loses.regular}</td>
              <td>{data.ad.loses.extra}</td>
            </tr>
            <tr>
              <td colspan="5">
                <h2>Total Games Played: {totalGames}</h2>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="chart-container">
          <div className="chart">
            <h2>First Game</h2>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={soloData}
                  cx="50%"
                  cy="50%"
                  outerRadius={60}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {soloData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <RechartsTooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <br />
          <br />
          <br />
          <div className="chart">
            <h2>Second Game</h2>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={adData}
                  cx="50%"
                  cy="50%"
                  outerRadius={60}
                  fill="#82ca9d"
                  dataKey="value"
                >
                  {adData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <RechartsTooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  };

  const toggleSpoiler = (type) => {
    if (type === "solo") {
      setShowSoloWord(!showSoloWord);
    } else if (type === "ad") {
      setShowAdWord(!showAdWord);
    }
  };
  const renderTodaysWordsBlock = () => {
    if (!data) {
      return <div>No words available for today.</div>;
    }

    return (
      <div className="words-block">
        <div className="spoiler-container">
          <div className="spoiler" onClick={() => toggleSpoiler("solo")}>
            {showSoloWord ? data.solo : "Show First Word"}
          </div>
          <div className="spoiler" onClick={() => toggleSpoiler("ad")}>
            {showAdWord ? data.ad : "Show Second Word"}
          </div>
        </div>
      </div>
    );
  };

  const getTimeLapsed = (timestamp) => {
    const now = new Date().getTime();
    const lapsed = now - timestamp * 1000;
    const seconds = Math.floor(lapsed / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} ${days === 1 ? "day" : "days"}`;
    }
    if (hours > 0) {
      return `${hours} ${hours === 1 ? "hour" : "hours"}`;
    }
    if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
    }
    return `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
  };

  const loadMoreWords = async (all = false) => {
    if (all) {
      setWordsPagination(data.words.length);
    } else {
      setWordsPagination(wordsPagination + 20);
    }
  };

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const addWord = async () => {
    if (newWord.trim() === "") return;
    await addDoc(collection(firestore, "unused_words"), { word: newWord });
    setNewWord("");
    loadMoreWords(true); // Reload words
  };

  const removeWord = async (id, collectionName) => {
    if (window.confirm("Are you sure you want to delete this word?")) {
      await deleteDoc(doc(firestore, collectionName, id));
      loadMoreWords(true); // Reload words
    }
  };
  const renderWordPoolBlock = () => {
    if (!data.words || !data.words.length) {
      return <div>No words available.</div>;
    }
    const invalidWordsCount = checkForInvalidWords(data.words).length;

    data.words.sort((a, b) => {
      if (sortConfig.key === "word") {
        return sortConfig.direction === "asc"
          ? a.word.localeCompare(b.word)
          : b.word.localeCompare(a.word);
      } else if (sortConfig.key === "status") {
        const statusA = a.status === "Used" ? 1 : 0;
        const statusB = b.status === "Used" ? 1 : 0;
        return sortConfig.direction === "asc"
          ? statusB - statusA
          : statusA - statusB;
      }
      return 0;
    });
    return (
      <div className="word-pool-block" lastVisible={data.lastVisible}>
        <div className="word-pool-stats">
          <p>
            Unused Words:{" "}
            {data.words.filter((word) => word.status === "Unused").length}
          </p>
          <p>
            Used Words:{" "}
            {data.words.filter((word) => word.status == "Used").length}
          </p>
          <p>Duplicate Words: {checkForDuplicates().length}</p>
          <p>Invalid Words: {invalidWordsCount}</p> {/* Add this line */}
          <button onClick={deleteDuplicates}>Delete Duplicates</button>{" "}
          <button onClick={deleteInvalidWords}>Delete Invalid Words</button>{" "}
          {/* Add this line */}
          <button onClick={() => downloadWords(data.words)}>
            Download Words
          </button>{" "}
          <input type="file" onChange={handleFileUpload} accept=".txt" />{" "}
        </div>
        <div className="add-word">
          <input
            type="text"
            value={newWord}
            onChange={(e) => setNewWord(e.target.value)}
            placeholder="Enter new word"
          />
          <button onClick={addWord}>Add Word</button>
        </div>
        <table className="blockTable" id="word-pool">
          <thead>
            <tr>
              <th onClick={() => sortTable("word")}>
                Word{" "}
                {sortConfig.key === "word" &&
                  (sortConfig.direction === "asc" ? "↓" : "↑")}
              </th>
              <th onClick={() => sortTable("status")}>
                Status{" "}
                {sortConfig.key === "status" &&
                  (sortConfig.direction === "asc" ? "↑" : "↓")}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="today" id="solo">
              <td>
                <a
                  href={`https://console.firebase.google.com/u/0/project/wordle-c7049/firestore/databases/-default-/data/~2Ftodays_words~2Fsolo`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.todaysWords.solo}
                </a>
              </td>
              <td>Today (1st)</td>
              <td>
                <IoIosRefresh
                  size={25}
                  onClick={() => changeWord("solo")}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                />
              </td>
            </tr>
            <tr class="today" id="ad">
              <td>
                <a
                  href={`https://console.firebase.google.com/u/0/project/wordle-c7049/firestore/databases/-default-/data/~2Ftodays_words~2Fsolo`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.todaysWords.ad}
                </a>
              </td>
              <td>Today (2nd)</td>
              <td>
                <IoIosRefresh
                  onClick={() => changeWord("ad")}
                  size={25}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                />
              </td>
            </tr>
            {data.words.slice(0, wordsPagination).map((word) => (
              <tr key={word.id}>
                <td>
                  <a
                    href={`https://console.firebase.google.com/u/0/project/wordle-c7049/firestore/databases/-default-/data/~2F${
                      word.status === "Used" ? "used_words" : "unused_words"
                    }~2F${word.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {word.word}
                  </a>
                </td>
                <td>{word.status}</td>
                <td>
                  <IoMdClose
                    size={25}
                    onClick={() =>
                      removeWord(
                        word.id,
                        word.status === "Used" ? "used_words" : "unused_words"
                      )
                    }
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.words.length > 20 && (
          <div>
            <button onClick={() => loadMoreWords()}>Load More</button>
            <button onClick={() => loadMoreWords(true)}>Load All Words</button>
          </div>
        )}
      </div>
    );
  };
  const checkForInvalidWords = () => {
    const invalidWords = (data.words || []).filter((wordObj) => {
      const charCount = {};
      for (const char of wordObj.word) {
        charCount[char] = (charCount[char] || 0) + 1;
        if (charCount[char] > 1) return true;
      }
      return false;
    });
    console.log("Invalid: " + invalidWords);

    return invalidWords;
  };

  const deleteInvalidWords = async () => {
    if (window.confirm("Are you sure you want to delete invalid words?")) {
      const invalidWords = checkForInvalidWords();

      const batch = writeBatch(firestore);

      invalidWords.forEach((wordObj) => {
        const collectionName =
          wordObj.status === "Used" ? "used_words" : "unused_words";
        batch.delete(doc(firestore, collectionName, wordObj.id));
      });

      await batch.commit();
    }
  };

  const changeWord = async (wordType) => {
    try {
      // Get the word from todays_words
      const docRef = doc(firestore, "todays_words", wordType);
      const docSnap = await getDoc(docRef);
      const word = docSnap.data().word;
      await addDoc(collection(firestore, "used_words"), { word: word });

      // If unused_words is empty, transfer all used_words into unused_words
      const unusedWordsSnapshot = await getDocs(
        collection(firestore, "unused_words")
      );
      if (unusedWordsSnapshot.empty) {
        const usedWordsSnapshot = await getDocs(
          collection(firestore, "used_words")
        );
        const usedWordsData = usedWordsSnapshot.docs.map((doc) => doc.data());
        const unusedWordsRef = collection(firestore, "unused_words");
        const batch = writeBatch(firestore);
        usedWordsData.forEach((word) =>
          batch.set(doc(unusedWordsRef), { word: word.word })
        );
        await batch.commit();
      }

      const unusedWords = unusedWordsSnapshot.docs.map((doc) => ({
        id: doc.id,
        word: doc.data().word,
      }));
      let randomIndex = Math.floor(Math.random() * unusedWords.length);
      let randomWord;
      do {
        randomWord = unusedWords[randomIndex];
        randomIndex = (randomIndex + 1) % unusedWords.length;
      } while (randomWord.word === word);

      // Update todays_words and delete the unused word
      await updateDoc(docRef, { word: randomWord.word });
      await deleteDoc(doc(firestore, "unused_words", randomWord.id));
    } catch (error) {
      console.error("Error changing word:", error);
    }
  };

  const checkForDuplicates = () => {
    const wordMap = new Map();

    data.words.forEach((word) => {
      if (!wordMap.has(word.word)) {
        wordMap.set(word.word, []);
      }
      wordMap.get(word.word).push(word);
    });

    const duplicates = [];
    wordMap.forEach((value) => {
      if (value.length > 1) {
        console.log(value);
        duplicates.push(value);
      }
    });
    console.log("Duplicates: " + duplicates);
    return duplicates;
  };

  const deleteDuplicates = async () => {
    if (window.confirm("Are you sure you want to delete duplicates?")) {
      const words = data.words;
      const duplicates = checkForDuplicates();

      const batch = writeBatch(firestore);

      duplicates.forEach((duplicateGroup) => {
        let usedWord = duplicateGroup.find(
          (wordObj) => wordObj.status === "Used"
        );
        if (!usedWord) {
          usedWord = duplicateGroup[0];
        }

        duplicateGroup.forEach((wordObj, index) => {
          if (wordObj.id !== usedWord.id) {
            const collectionName =
              wordObj.status === "Used" ? "used_words" : "unused_words";
            batch.delete(doc(firestore, collectionName, wordObj.id));
          }
        });
      });

      await batch.commit();
    }
  };

  const downloadWords = (words) => {
    const text = words.map((word) => word.word).join("\n");
    const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "words.txt");
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const words = text
        .split("\n")
        .map((word) => word.trim())
        .filter((word) => word);

      const batch = writeBatch(firestore);
      words.forEach((word) => {
        const wordRef = doc(collection(firestore, "unused_words"));
        batch.set(wordRef, { word });
      });

      await batch.commit();
    };
    reader.readAsText(file);
  };

  switch (type) {
    case "online":
      return (
        <div className="block">
          <h1>מחוברים ({data.length})</h1>
          {renderOnlineBlock()}
        </div>
      );
    case "stats":
      return (
        <div className="block">
          <h1>Statistics</h1>

          <div className="stats-header">
            <h2>Statistics for {props.selectedDate}</h2>
            <div className="date-picker-container">
              <FaArrowRight
                color="white"
                size={35}
                onClick={() => handleDateChange(1)}
                className="date-arrow"
              />
              <input
                type="date"
                value={props.selectedDate
                  .split("-")
                  .map((part) => (part.length === 1 ? "0" + part : part))
                  .reverse()
                  .join("-")}
                onChange={(e) => {
                  let newFormat = e.target.value.split("-").reverse().join("-");
                  if (newFormat.charAt(0) === "0") {
                    newFormat = newFormat.slice(1);
                  }
                  newFormat = newFormat.replace("-0", "-");
                  props.setSelectedDate(newFormat);
                }}
              />
              <FaArrowLeft
                color="white"
                size={35}
                onClick={() => handleDateChange(-1)}
                className="date-arrow"
              />
            </div>
          </div>
          {renderStatsBlock()}
        </div>
      );

    case "words":
      return (
        <div className="block">
          <h1>Today's Secret Words</h1>
          {renderTodaysWordsBlock()}
        </div>
      );
    case "wordPool":
      return (
        <div className="block">
          <h1>Word Pool Management</h1>
          {renderWordPoolBlock()}
        </div>
      );
    default:
      return <div />;
  }
};

export default Block;
