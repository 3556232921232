// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyChQk0izeeaHKTPsCjITCHdGlQ8IWFkHqo",
  authDomain: "wordle-c7049.firebaseapp.com",
  databaseURL:
    "https://wordle-c7049-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wordle-c7049",
  storageBucket: "wordle-c7049.appspot.com",
  appId: "1:378789649226:android:eccabbbfbc6c754a173fb3",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);
const firestore = getFirestore(app);
const database = getDatabase(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

export { auth, firestore, database, storage, googleProvider };
